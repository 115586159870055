import { createBrowserRouter } from 'react-router-dom'
import Default from '@layouts/Default'
import ProtectedRoute from '@components/ProtectedRoute'
import Dashboard from '@pages/Dashboard'
import Tickets from '@pages/Tickets'
import Ticket from '@pages/Ticket'
import Error from '@pages/Error'
import Company from '@pages/Company'
import TicketRating from '@pages/TicketRating'
import Backup from '@pages/Backup'
import Autofill from '@pages/AutoFill'
import OverviewTickets from '@pages/OverviewTickets'
import Monitoring from '@pages/Monitoring'
import Users from '@pages/Users'
import Hr from '@pages/Hr'
import Quotes from '@pages/Quotes'
import Companies from '@pages/Companies'
import EditDevice from '@pages/EditDevice'
import Search from '@pages/Search'
import Device from '@pages/Device'
import Services from '@pages/Services'
import Servers from '@pages/Servers'
import Billing from '@pages/Billing'
import PageWithParams from '@pages/PageWithParams'
import Logout from '@pages/Logout'
import StatusProblem from '@pages/StatusProblem'
import Sectors from '@pages/Sectors'
import Tiers from '@pages/Tiers'
import Products from '@pages/Products'
import Articles from '@pages/Articles'
import Article from '@pages/Article'
import Orders from '@pages/Orders'
import CreateArticle from '@pages/CreateArticle'
import EditArticle from '@pages/EditArticle'
import ArticleCategories from '@pages/ArticleCategories'
import Dns from '@pages/Dns'
import Onboard from '@pages/Onboard'
import ServerLogs from '@pages/ServerLogs'
import Status from '@pages/Status'
import Churn from '@pages/Churn'
import Discovery from '@pages/Discovery'
import AcademicPartner from '@pages/AcademicPartner'
import Receipt from '@pages/Receipt'
import RedirectPage from '@components/common/RedirectPage'
import HrJob from '@pages/HrJob'

const PrivateRoute = createBrowserRouter([
  {
    path: '/',
    element: <Default />,
    errorElement: <Error />,
    children: [
      {
        path: '*',
        element: <Error code={404} />
      },
      {
        path: '/',
        element: (
          <PageWithParams name="dashboard">
            <Dashboard />
          </PageWithParams>
        )
      },
      {
        path: '/articles/:slug',
        element: <Article />
      },
      {
        path: '/articles',
        element: <Articles />
      },
      {
        path: '/articles/create',
        element: <CreateArticle />
      },
      {
        path: '/articles/:slug/edit',
        element: <EditArticle />
      },
      {
        path: '/articles/categories',
        element: (
          <ProtectedRoute level={950}>
            <ArticleCategories />
          </ProtectedRoute>
        )
      },
      {
        path: '/search',
        element: <Search />
      },
      {
        path: '/billing',
        element: <Billing />
      },
      {
        path: '/tickets',
        element: (
          <PageWithParams name="tickets">
            <Tickets />
          </PageWithParams>
        )
      },
      {
        path: '/ticket/:tkid',
        element: <Ticket />
      },
      {
        path: '/tickets/services',
        element: (
          <ProtectedRoute level={899}>
            <Services />
          </ProtectedRoute>
        )
      },
      {
        path: '/tickets/autofill',
        element: (
          <ProtectedRoute>
            <Autofill />
          </ProtectedRoute>
        )
      },
      {
        path: '/servers',
        element: (
          <ProtectedRoute level={600}>
            <Servers />
          </ProtectedRoute>
        )
      },
      {
        path: '/device/:uuid',
        element: <Device />
      },
      {
        path: '/device/:uuid/edit',
        element: (
          <ProtectedRoute>
            <EditDevice />
          </ProtectedRoute>
        )
      },
      {
        path: '/monitoring',
        element: <Monitoring />
      },
      {
        path: '/users',
        element: (
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        )
      },
      {
        path: '/hr',
        element: (
          <ProtectedRoute>
            <Hr />
          </ProtectedRoute>
        )
      },
      {
        path: '/hr/job',
        element: (
          <ProtectedRoute>
            <HrJob />
          </ProtectedRoute>
        )
      },
      {
        path: '/quotes',
        element: (
          <ProtectedRoute>
            <Quotes />
          </ProtectedRoute>
        )
      },
      {
        path: '/orders',
        element: (
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>
        )
      },
      {
        path: '/backups',
        element: <Backup />
      },
      {
        path: '/customers',
        element: (
          <ProtectedRoute>
            <Companies />
          </ProtectedRoute>
        )
      },
      {
        path: '/company/:uuid',
        element: (
          <ProtectedRoute>
            <Company />
          </ProtectedRoute>
        )
      },
      {
        path: '/tickets/sectors',
        element: (
          <ProtectedRoute level={901}>
            <Sectors />
          </ProtectedRoute>
        )
      },
      {
        path: '/tickets/tiers',
        element: (
          <ProtectedRoute level={901}>
            <Tiers />
          </ProtectedRoute>
        )
      },
      {
        path: '/status/problem',
        element: (
          <ProtectedRoute>
            <StatusProblem />
          </ProtectedRoute>
        )
      },
      {
        path: '/discovery',
        element: (
          <ProtectedRoute>
            <Discovery />
          </ProtectedRoute>
        )
      },
      {
        path: '/products',
        element: (
          <ProtectedRoute level={750}>
            <Products />
          </ProtectedRoute>
        )
      },
      {
        path: '/cancelamento',
        element: <Churn />
      },
      {
        path: '/dns',
        element: (
          <ProtectedRoute level={900}>
            <Dns />
          </ProtectedRoute>
        )
      },
      {
        path: '/servers/logs',
        element: (
          <ProtectedRoute level={900}>
            <ServerLogs />
          </ProtectedRoute>
        )
      },
      {
        path: '/status',
        element: <Status />
      },
      {
        path: '/academicPartner',
        element: (
          <ProtectedRoute>
            <AcademicPartner />
          </ProtectedRoute>
        )
      },
      {
        path: '/receipt',
        element: <Receipt />
      }
    ]
  },
  {
    path: '/onboard/customer/:uuid',
    element: <Onboard />
  },
  {
    path: '/tickets/tv',
    element: (
      <ProtectedRoute>
        <OverviewTickets />
      </ProtectedRoute>
    )
  },
  {
    path: '/ticket/rating/:uuid',
    element: <TicketRating />
  },
  {
    path: '/register',
    element: <RedirectPage to="/" />
  },
  {
    path: '/logout',
    element: <Logout />
  },
  {
    path: '*',
    element: <Error code={404} />
  }
])

export default PrivateRoute
