import { Row, Col } from 'antd'
import Title from '@common/Title'
import ResponsiveTableCard from '@components/common/ResponsiveTableCard'
import ModalJob from '@components/HrJob/Modal'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { columns } from '@components/HrJob/Table'

const HrJob = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Row>
      <Col span={24}>
        <Title name='RH' />
      </Col>
      <Col xl={{ span: 3, offset: 21 }} xs={24}>
      </Col>
      <Col span={24}>
        <ResponsiveTableCard
          style={{ width: '100%' }}
          {...{ columns, url: '/hr/job' }}
        >
          <Col xl={{ span: 2, offset: 22 }} xs={24}>
            <ModalJob
              block
              name="fa-light fa-plus"
              style={{ backgroundColor: theme.green }}
              mode="create"
            >
              {t('CREATEJOB')}
            </ModalJob>
          </Col>
        </ResponsiveTableCard>
      </Col>
    </Row >
  )
}

export default HrJob
