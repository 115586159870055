import { useMemo } from 'react'
import useModal from '@hooks/useModal'
import { Form, Row, Col, InputNumber, message, Typography, Space } from 'antd'
import Modal from '@common/Modal'
import InputWithForm from '@common/InputWithForm'
import { api } from '@helpers/api'
import IconButton from '@common/IconButton'
import { withTranslation, useTranslation } from 'react-i18next'
import { IconButtonProps } from '@/types/IconButtonProps'
import { TFunction } from 'i18next'
import { ModalContext } from '@contexts/ModalContext'
import { IHrJob } from '@/types/IHrJob'
import useTableContext from '@hooks/useTableContext'
import ModalFooter from '@components/common/ModalFooter'
import MarkdownInput from '@components/common/MarkdownInput'
import { useTheme } from 'styled-components'

interface ModalHrJobsProps extends IconButtonProps {
  t: TFunction
  uuid?: string
  data?: IHrJob
  mode: 'edit' | 'create'
  numForm: string
  onCancel: () => void
}

const onRequest = (
  uuid: string | undefined,
  values: IHrJob,
  mode: 'edit' | 'create'
) => {
  if (mode === 'create') {
    return api.post('hr/job', values)
  }
  return api.put(`hr/job/${uuid}`, values)
}

const ModalContentJob = withTranslation()(({
  data,
  mode,
  numForm,
  onCancel,
}: Omit<ModalHrJobsProps, 'uuid' | 'name'>) => {
  const [form] = Form.useForm()
  const { refreshTable } = useTableContext()
  const { t } = useTranslation()
  const theme = useTheme()
  const fields = [
    { name: 'title', label: t('TITLEJOB'), type: 'input' },
    { name: 'title_en', label: t('TITLEJOB_EN'), type: 'input' },
    { name: 'body', label: t('BODY'), type: 'textarea', rows: 12 },
    { name: 'body_en', label: t('BODY_EN'), type: 'textarea', rows: 12 },
    { name: 'function', label: t('FUNCTION'), type: 'input' },
    { name: 'function_en', label: t('FUNCTION_EN'), type: 'input' },
    { name: 'addr', label: t('ADDRESS'), type: 'input' },
  ];

  const handleFinish = async (values: IHrJob) => {
    try {
      await onRequest(data?.uuid, values, mode)
      message.success(t('SUCCESSJOB'))
      onCancel()
      refreshTable('/hr/job')
    } catch (error) {
      message.error(t('ERRORJOB'))
    }
  }

  return (
    <Form
      name="job"
      layout="vertical"
      initialValues={data}
      form={form}
      onFinish={handleFinish}
      id={numForm}
    >
      <Row gutter={[16, 16]} justify="space-between">
        {fields.map((field, index) => (
          <Col key={`${index}-${field.name}`} xs={24} sm={12}>
            {field.type === 'input' ? (
              <InputWithForm required={true} name={field.name} label={field.label} />
            ) : (
              <Space direction='vertical' style={{ width: '100%' }}>
                <Typography.Text><span style={{ color: theme.red }}>*</span> {t(field.label)}</Typography.Text>
                <MarkdownInput
                  rules={[{ required: true, message: t('requiredItem') }]}
                  name={field.name}
                />
              </Space>
            )}
          </Col>
        ))}
        <Col xs={24} sm={12}>
          <Form.Item
            rules={[{ required: true, message: t('requiredItem') }]}
            name='positions' label={t('AMOUNT')}>
            <InputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
})

const ModalJob = ({
  data,
  mode,
  ...props
}: Omit<ModalHrJobsProps, 't' | 'onCancel'>) => {
  const { t } = useTranslation()
  const { open, onOpen, onCancel } = useModal()
  const memoOpen = useMemo(
    () => ({ open, onCancel, onOpen }),
    [open, onCancel, onOpen]
  )
  const crypto = window.crypto
  let array = new Uint32Array(1)
  const numForm = crypto.getRandomValues(array)[0].toString()
  return (
    <ModalContext.Provider value={memoOpen}>
      <Modal
        width='70%'
        footer={
          <ModalFooter onClose={onCancel} action={mode} form={numForm} />
        }
        title={mode === 'create' ? t('CREATEJOB') : t('EDITJOB')}
        {...{ open, onOpen, onCancel }}
      >
        <ModalContentJob{...{ data, mode, numForm, onCancel }} />
      </Modal>
      <IconButton {...props} onClick={onOpen} id={`job-${mode}`} />
    </ModalContext.Provider>
  )
}

export default ModalJob
