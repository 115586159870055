import { useState, useEffect } from 'react'
import useSocket from '@hooks/useSocket'
import { Card, Row, Col, Checkbox, Button, Typography, Progress } from 'antd'
import { useTheme } from 'styled-components'
import { api } from '@helpers/api'
import useFetch from '@hooks/useFetch'
import { useUser } from '@contexts/UserContext'
import useWindowResize from '@hooks/useWindowResize'
import dayjs from 'dayjs'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { IStep, ITask } from '@/types/IStep'
import { TicketContext } from '@contexts/TicketContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { ISector } from '@/types/ISector'
import { useTranslation } from 'react-i18next'
import SubTitle from '@components/common/SubTitle'
import { DATEFORMAT } from '@constants/dateformat'
import { Space } from 'antd/lib'

interface StepCardProps {
  collapse: boolean
  step: IStep
  tkid: string | number
}

const stepsStyles = {
  container: isRowBased => ({
    borderWidth: '0.5px',
    padding: '8px 4px',
    borderStyle: 'solid',
    borderRadius: '5px',
    flexDirection: isRowBased ? 'row' : 'column'
  })
}

const querySteps = async (tkid: string | number) => {
  return await api.get(`/ticket/${tkid}/step`)
}

const checkStepsorTasks = async (
  tkid: string | number,
  action: { steps: string[]; tasks: string[] }
) => {
  return await api.put(`/ticket/${tkid}/step`, action)
}

const useTaskorStep = (tkid, type, item) => {
  const [checked, setChecked] = useState(item.check_time !== null)
  const onChange = async (e: CheckboxChangeEvent) => {
    const obj = { steps: [], tasks: [] }
    obj[type as 'steps' | 'tasks'] = [e.target.value]
    await checkStepsorTasks(tkid, obj)
      .then(() => setChecked(true))
      .catch(() => setChecked(false))
  }
  return { checked, onChange }
}

interface CheckProps {
  item: any
  tkid: string | number
  type: 'tasks' | 'steps'
  readOnly: boolean
}
const Check = ({ item, tkid, type, readOnly }: CheckProps) => {
  const { checked, onChange } = useTaskorStep(tkid, type, item)
  const { t } = useTranslation()
  console.log(item)
  return (
    <Col className='w-full'>
      <Space direction="vertical" >
        <Checkbox
          onChange={onChange}
          disabled={readOnly || checked}
          checked={checked}
          value={item.id}
        >
          {type === 'tasks' ? item.task : t('SECTORCHECK')}
        </Checkbox>
        {item?.updated_by ? (
          `- ${item.updated_by.name} ${dayjs(item?.updated_at).format(DATEFORMAT)}`
        ) : null}
      </Space>
    </Col>
  )
}

const Tasks = ({ step, tkid }: { step: IStep; tkid: string | number }) => {
  const { user } = useUser()
  const [readOnly, setReadOnly] = useState(true)
  useEffect(() => {
    const verify = user.sectors
      ? !user.sectors.some(
        (sector: { sector: ISector }) =>
          sector?.sector?.slug === step?.sector?.slug
      )
      : false
    setReadOnly(verify && user.level < 1000)
  }, [step])
  return (
    <div data-cy="ticket-step-tasks">
      {step.tasks.length ? (
        step.tasks.map((task: ITask) => {
          return (
            <Check
              readOnly={readOnly}
              key={task.id}
              item={task}
              tkid={tkid}
              type="tasks"
            />
          )
        })
      ) : (
        <Row>
          <Check
            {...{ readOnly, tkid }}
            data-cy="ticket-step-sectorcheck"
            item={step}
            type="steps"
          />
        </Row>
      )}
    </div>
  )
}

const Description = ({ step }: { step: IStep }) => {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    const steps = step.tasks.length ? step.tasks.length : 1
    if (step.tasks?.length) {
      const check = step.tasks.filter((task: ITask) => task.check_time !== null)
      setPercent((100 * check.length) / steps)
    } else {
      const newPercent = (step.check_time !== null ? 1 : 0) / steps
      setPercent(100 * newPercent)
    }
  }, [step])

  return (
    <Row justify="center">
      <Progress
        percent={percent}
        steps={step.tasks.length ? step.tasks.length : 1}
        showInfo={false}
      />
      <Col span={24}>
        {step.check_time ? (
          <>
            <Typography.Text
              data-cy="ticket-step-checkat-time"
              style={{ textAlign: 'center', display: 'block' }}
            >
              {dayjs(step.check_time).format(DATEFORMAT)}
            </Typography.Text>
            <Typography.Text style={{ textAlign: 'center', display: 'block' }}>
              {step.updated_by?.name ? step.updated_by.name : null}
            </Typography.Text>
          </>
        ) : null}
      </Col>
    </Row>
  )
}

const StepCard = ({ step, collapse, tkid, ...props }: StepCardProps) => {
  const theme = useTheme()
  const isRowBased = useWindowResize().width < 720
  const styles = {
    ...stepsStyles.container(isRowBased),
    borderColor: theme.gray
  }
  return (
    <Col span={24} xl={{ span: 8 }} style={styles}>
      <Row {...props}>
        <Col span={24} style={{ alignItems: 'center' }}>
          <Typography.Title style={{ textAlign: 'center' }} level={5}>
            {step?.sector?.name}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Description step={step} />
        </Col>
        <Col style={{ paddingLeft: '8px', margin: '8px 0' }}>
          {collapse ? <Tasks step={step} tkid={tkid} /> : null}
        </Col>
      </Row>
    </Col>
  )
}

const useTicketChecks = () => {
  const context = useGenericContext(TicketContext)
  const [collapse, setCollapse] = useState(false)
  const { data, setData } = useFetch({
    func: () => querySteps(context?.data?.tkid),
    deps: [context?.data?.tkid]
  })
  const toogleCollapse = () => setCollapse(!collapse)

  const updateChecks = async () => {
    querySteps(context.data.tkid)
      .then(e => {
        setData(e.data)
      })
      .catch(() => false)
  }
    ;['TicketStepTasksUpdated', 'TicketStepsUpdated'].forEach(event => {
      useSocket(updateChecks, event, `tickets.${context?.data?.tkid}`)
    })

  return { data, collapse, toogleCollapse, tkid: context?.data?.tkid }
}

const MaximizeMinimizeSteps = ({ toogle }: { toogle: () => void }) => {
  return (
    <Button
      data-cy="ticket-step-collapse"
      type="text"
      className="fa-regular fa-arrows-maximize"
      onClick={toogle}
    />
  )
}

const CheckTitle = () => {
  return (
    <SubTitle level={3} data-cy="checklist-title">
      Checklist
    </SubTitle>
  )
}

const TicketChecks = () => {
  const { data, collapse, toogleCollapse, tkid } = useTicketChecks()
  return (
    <>
      <CheckTitle />
      <Card
        data-cy="page-ticket-check-card"
        extra={<MaximizeMinimizeSteps toogle={toogleCollapse} />}
      >
        <Row type="flex" gutter={[8, 8]}>
          {data.data && data.data.length
            ? data.data.map((step: IStep) => (
              <StepCard
                tkid={tkid}
                data-cy="ticket-step-card"
                key={step.id}
                step={step}
                collapse={collapse}
              />
            ))
            : false}
        </Row>
      </Card>
    </>
  )
}

export default TicketChecks
