import { useState, } from 'react'
import Modal from '@components/common/Modal'
import { ITargetTier, IUserTiers } from '@/types/IUserTiers'
import {
  Button,
  ButtonProps,
  Col,
  ColProps,
  Form,
  Input,
  Row,
  Select
} from 'antd'
import useModal from '@hooks/useModal'
import { MinusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ModalContext } from '@contexts/ModalContext'
import useSearchSelect from '@hooks/useSearchSelect'
import Icon from '@components/common/Icon'
import InputWithForm from '@components/common/InputWithForm'
import { SearchSelectWithForm } from '@components/common/SearchSelectWithForm'
import { api } from '@helpers/api'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { MessageContext } from '@contexts/MessageContext'
import { useGenericContext } from '@hooks/useGenericContext'
import useTableContext from '@hooks/useTableContext'
import { handleError } from '@helpers/handleError'
import { AxiosResponse } from 'axios'
import { mapItemswithNameandUUIDFilter } from '@helpers/mapItemswithNameandUUIDFilter'
import { IUser } from '@/types/IUser'
const Option = Select.Option

const FullCol = (props: ColProps) => <Col span={24} {...props} />
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 20, offset: 4 }
  }
}

interface TierModalProps extends ButtonProps {
  data?: IUserTiers | undefined
}

const convertToOption = (e: obj) => ({
  children: e.slug,
  key: e.slug,
  label: e.slug
})

const searchTiers = async (search = '') =>
  await api.get(`/user/tier?search=${search}`).then(e => e.data.data)

const TargetSelect = () => {
  const { options, ...props } = useSearchSelect({
    initialValue: undefined,
    func: searchTiers
  })
  return (
    <SearchSelectWithForm
      required={false}
      name="targets"
      mode="multiple"
      label="TARGETS"
      {...props}
    >
      {Array.isArray(options)
        ? options?.map((e: ITargetTier) => <Option {...convertToOption(e)} />)
        : null}
    </SearchSelectWithForm>
  )
}

const FormLabelItem = ({ field, remove, index }: any) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      label={index === 0 ? t('LABELS') : null}
      key={field.key}
      {...{ formItemLayoutWithOutLabel }}
    >
      <Form.Item {...field} noStyle>
        <Input
          placeholder="label"
          style={{ width: '80%', marginRight: '10px' }}
        />
      </Form.Item>
      <MinusCircleOutlined
        onClick={() => remove(field.name)}
        className="dynamic-delete-button"
      />
    </Form.Item>
  )
}

const AddPhoneButton = ({ add }: { add: () => void }) => {
  const { t } = useTranslation()
  return (
    <Button block onClick={() => add()} type="dashed" data-cy="add-label">
      {t('ADDLABEL')}
    </Button>
  )
}

const LabelsList = () => {
  const { t } = useTranslation()
  return (
    <Form.List name="labels" label={t('PHONE')}>
      {(fields, { remove, add }) => (
        <>
          {fields.map((field, index) => (
            <FormLabelItem
              field={field}
              remove={remove}
              index={index}
              key={field.key}
            />
          ))}
          <Col span={24}>
            <AddPhoneButton add={add} />
          </Col>
        </>
      )}
    </Form.List>
  )
}

const initialValues = (data: IUserTiers | undefined) => {
  if (data)
    return {
      ...data,
      targets: data?.targets?.map(e => e.name),
      users: data?.users?.map(e => e.uuid)
    }
  return data
}


const useUserSelect = (data: IUserTiers | undefined) => {

  const remove = async (uuid: string) => {
    let user = data?.users?.find(e => e.uuid === uuid)
    if (!user) {
      user = await api.get(`/user/${uuid}`).then(e => e.data)
    }
    await api.put(`/user/${user?.uuid}`, {
      tiers: user?.tiers?.map(e => e.slug).filter(i => i !== data?.slug)
    })
  }

  const add = async (uuid: string) => {
    let user = data?.users?.find(e => e.uuid === uuid)
    if (!user) {
      user = await api.get(`/user/${uuid}`).then(e => e.data)
    }
    const userTiers = user?.tiers?.map((e) => e.slug) || []
    if (user) await api.put(`/user/${user.uuid}`, {
      tiers: [...userTiers, data?.slug]
    })
  }

  return { remove, add }
}


const UserSelect = ({ data }: { data: IUserTiers | undefined }) => {
  const { remove, add } = useUserSelect(data)
  const { t } = useTranslation()
  const userProps = useSearchSelect({
    func: async (search = '') => await mapItemswithNameandUUIDFilter(`susers?search=${search}&perPage=999`)
  })
  return (
    <SearchSelectWithForm
      name="users"
      mode="multiple"
      onDeselect={remove}
      onSelect={add}
      label={t('USERS')}
      span={24}
      {...userProps}
    />
  )
}

const ModalForm = ({ data }: { data: IUser }) => {
  const { t } = useTranslation()
  return (
    <Row>
      {['name', 'slug'].map(e => (
        <FullCol key={e}>
          <InputWithForm required name={e} label={t(e.toUpperCase())} />
        </FullCol>
      ))}
      <TargetSelect />
      {data ? <UserSelect data={data} /> : null}
      <LabelsList />
    </Row>
  )
}

const ModalFooter = () => {
  const form = useFormInstance()
  const { t } = useTranslation()
  return (
    <Button
      data-cy="submit-tier-modal"
      htmlType="submit"
      type="primary"
      onClick={form.submit}
      disabled={form.isFieldsValidating()}
    >
      {t('CONFIRM')}
    </Button>
  )
}

const requestTiers = async (data: IUserTiers | undefined, values: any) => {
  return data
    ? await api.put(`/user/tier/${data.uuid}`, values)
    : await api.post('/user/tier', values)
}

const useOnSucess = () => {
  const form = useFormInstance()
  const { t } = useTranslation()
  const { onClose } = useGenericContext(ModalContext)
  const { update } = useTableContext()
  const messageApi = useGenericContext(MessageContext)

  const success = (
    tier: AxiosResponse<IUserTiers>,
    data: IUserTiers | undefined
  ) => {
    messageApi.success(
      t(data ? 'SUCCESSUPDATINGTIER' : 'SUCCESSCREATINGTIER'),
      2000
    )
    update && update(tier.data.uuid, tier.data)
    onClose?.()
    form.resetFields()
  }
  return success
}

const useTiersModal = (data: IUserTiers | undefined) => {
  const [disabled, setDisabled] = useState(false)
  const success = useOnSucess()

  const onFinish = async (values: any) => {
    setDisabled(true)
    await requestTiers(data, values)
      .then(e => success(e, data))
      .catch(err => handleError(err, err?.data?.message))
      .finally(() => setDisabled(false))
  }
  return { onFinish, disabled }
}

const OpenModalButton = (props: ButtonProps) => {
  const { onOpen } = useGenericContext(ModalContext)
  return (
    <Button
      {...props}
      onClick={onOpen}
      icon={<Icon name="fa-light fa-edit" color="white" />}
    />
  )
}

const InternalTierModal = ({ data, ...props }: TierModalProps) => {
  const { t } = useTranslation()
  const { onClose, open } = useGenericContext(ModalContext)
  const [form] = Form.useForm()
  const { onFinish, disabled } = useTiersModal(data)
  return (
    <Form
      form={form}
      initialValues={initialValues(data)}
      {...{ onFinish, disabled }}
    >
      <Modal
        destroyOnClose
        onCancel={onClose}
        width={'30%'}
        open={open}
        title={data ? t('EDITTIER') + ' ' + data.name : t('CREATETIER')}
        footer={<ModalFooter />}
      >
        <ModalForm data={data} />
      </Modal>
      <OpenModalButton {...props} />
    </Form>
  )
}

const TiersModal = (props: TierModalProps) => {
  const { open, onOpen, onClose } = useModal()

  return (
    <ModalContext.Provider value={{ open, onOpen, onClose }}>
      <InternalTierModal {...props} />
    </ModalContext.Provider>
  )
}

export default TiersModal
