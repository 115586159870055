import { useState } from 'react'
import { api } from '@helpers/api'
import { Popconfirm, Space, Button } from 'antd'
import ModalJob from '@components/HrJob/Modal'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import TextMarkdown from '@components/common/TextMarkdown'
import useTableContext from '@hooks/useTableContext'
import { IHrJob } from '@/types/IHrJob'

const DeleteJob = ({ uuid }: { uuid: string }) => {
  const { refreshTable } = useTableContext()
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)

  const deleteJob = async () => {
    setDisabled(true)
    api.delete(`/hr/job/${uuid}`).then(() => setDisabled(false))
    refreshTable('/hr/job')
  }
  return (
    <Popconfirm
      disabled={disabled}
      title={t('CONFIRMDELETEJOB')}
      onConfirm={deleteJob}
    >
      <Button
        data-cy="deleteJob"
        danger
        icon={<Icon name="fa-light fa-trash" color="white" />}
        style={{ color: 'white' }}
        type="primary"
      />
    </Popconfirm>
  )
}

const EditJob = ({ data }: { data: IHrJob }) => {
  return (
    <ModalJob
      data={data}
      name="fa-light fa-pen"
      mode="edit"
      data-cy="editJob"
    />
  )
}

const JobActions = ({ data }: { data: IHrJob }) => {
  return (
    <Space>
      <EditJob data={data} />
      <DeleteJob uuid={data.uuid} />
    </Space>
  )
}
export const columns: ColumnsType<IHrJob> = [
  {
    title: 'ACTIONS',
    dataIndex: 'action',
    key: 'action',
    render: (_, data) => <JobActions {...{ data }} />
  },
  {
    title: 'TITLEJOB',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'BODY',
    dataIndex: 'body',
    key: 'body',
    render: body => <TextMarkdown
      text={body.length > 100 ? `${body.substring(0, 100)}...` : body}
    />
  },
  {
    title: 'FUNCTION',
    dataIndex: 'function',
    key: 'function'
  },
  {
    title: 'TITLEJOB_EN',
    dataIndex: 'title_en',
    key: 'title_en'
  },
  {
    title: 'BODY_EN',
    dataIndex: 'body_en',
    key: 'body_en',
    render: body => <TextMarkdown
      text={body.length > 100 ? `${body.substring(0, 100)}...` : body}
    />
  },
  {
    title: 'FUNCTION_EN',
    dataIndex: 'function_en',
    key: 'function_en',
  },
  {
    title: 'AMOUNT',
    dataIndex: 'positions',
    key: 'positions',
  },
  {
    title: 'ADDRESS',
    dataIndex: 'addr',
    key: 'addr',
  }
]
