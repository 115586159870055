import { Col, Input, Row, Select, Form, Button, Card, InputNumber, Divider } from "antd"
import { useTranslation } from "react-i18next"
import {
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import { FormListProps } from "antd/es/form"
import { SearchSelectWithForm } from "@components/common/SearchSelectWithForm"
import InputWithForm from "@components/common/InputWithForm"
import { DefaultOptionType } from "antd/es/select"

const raidTypes = ['NON-RAID',
  'RAID-0', 'RAID-1', 'RAID-2', 'RAID-4', 'RAID-5', 'RAID-6', 'RAID-10', 'RAID-50', 'RAID-60', 'RAID-Z1', 'RAID-Z2', 'RAID-Z3']

const diskTypes = ['sas', 'sata', 'ssd', 'nvme']

const diskInchesTypes = ['2.5', '3.5', 'PCIe']

const convertKeysToOptions = (array: string[] | null): DefaultOptionType[] => {
  if (!array) return []
  return array.map(e => ({ label: e, value: e }))
}

const EditDisks = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <Form.Item label={t("DISKS")}>
        <Form.List name='disks'>
          {(fields, { add, remove }) => (
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <>
                  {
                    fields.map(({ key, name }) => (
                      <Card key={key}>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <Form.Item name={[name, 'type']} label={t('TYPE')}>
                              <Select options={convertKeysToOptions(diskTypes)} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <InputWithForm name={[name, 'group_id']} label={t('GROUP') + ' #'} />
                          </Col>
                          <Col span={12}>
                            <InputWithForm name={[name, 'model']} label={t('MODEL')} />
                          </Col>
                          <Col span={6}>
                            <Form.Item name={[name, 'size']} label={t('SIZE')}>
                              <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name={[name, 'inches']} label={t('INCHES')}>
                              <Select options={convertKeysToOptions(diskInchesTypes)} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Button danger onClick={() => remove(name)} type='primary' block>
                          {t("REMOVEITEM")}
                          <MinusCircleOutlined />
                        </Button>
                      </Card>
                    ))}
                </>
                <Button
                  data-cy="add-disks"
                  type="primary"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  block
                >
                  {t('ADDITEM')}
                </Button>

              </Row>
            </Col>
          )}
        </Form.List>
      </Form.Item>
    </Col >
  )
}
const EditDiskLayouts = (props: Omit<FormListProps, 'children'>) => {
  const { t } = useTranslation()
  return (
    <Col span={12}>
      <Form.Item label={t('LAYOUTGROUPS')}>
        <Form.List {...props}>
          {(fields, { add, remove }) => (
            <Row gutter={[8, 8]}>
              {fields.map(({ key, name, ...restField }) => (
                <Card key={key} style={{ width: '100%' }}>
                  <Row gutter={[8, 8]} align='middle'>
                    <SearchSelectWithForm
                      options={convertKeysToOptions(raidTypes)}
                      label={t('LAYOUTGROUP') + ' - ' + key}
                      name={[name, 'layout']}
                      span={24}
                      {...restField}
                    />
                    <Col span={10}>
                      <InputWithForm label={t('NAME')} name={[name, 'name']} />
                    </Col>
                    <Col span={10}>
                      <Form.Item name={[name, 'capacity']} label={t("CAPACITY")} >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingTop: '4px' }}>
                      <Button danger onClick={() => remove(name)} type='primary' block>
                        <MinusCircleOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ))}
              <Button data-cy="add-layout" type="primary" onClick={() => add()}
                icon={<PlusOutlined />} block>
                {t('ADDITEM')}
              </Button>

            </Row>
          )}
        </Form.List>
      </Form.Item>
    </Col >
  )
}
const EditDiskControllers = () => {
  const { t } = useTranslation()
  return (
    <>
      <Col xs={{ span: 24 }} xl={{ span: 16 }}>
        <Row gutter={[8, 8]}>
          <Form.List name='diskcontrollers' >
            {(fields, { add }) => (
              <Col span={24}>
                {fields.map(({ key, name, ...restField }) => (
                  <Row style={{ width: '100%' }} key={key} gutter={[8, 8]}>
                    <Col key={key} span={12}>
                      <Form.Item {...restField} name={[name, 'model']} label={t('MODEL')}>
                        <Input />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'types']} label={t('DISKTYPESAVALIABLES')}>
                        <Select mode="multiple" options={
                          convertKeysToOptions(diskTypes)
                        }
                        />
                      </Form.Item>
                    </Col>
                    <EditDiskLayouts {...restField} name={[name, 'layouts']} />
                    <Divider />
                  </Row>
                ))}
                <Button type="primary" onClick={() => add()}
                  icon={<PlusOutlined />} block
                  data-cy="add-diskcontrollers"
                >
                  {t('ADDITEM')}
                </Button>
              </Col>
            )}
          </Form.List>
        </Row>
      </Col>
      <EditDisks />
    </>
  )
}
const EditDeviceHardware = () => {
  const { t } = useTranslation()
  return (
    <>
      <Divider>{t('DISKCONTROLLER')} </Divider>
      <EditDiskControllers />
    </>
  )
}

export default EditDeviceHardware
