import { AxiosError, AxiosResponse } from "axios"
import { api } from '@helpers/api'
import { Dispatch, SetStateAction, useEffect, useState } from "react"

interface IDeviceSecrets {
  manager?: Array<{
    user: string
    password: string
  }>
  support?: Array<{
    user: string
    password: string
  }>
}

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

function b642ab(base64: string) {
  const binary_string = window.atob(base64)
  const len = binary_string.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

function importKey(key: string) {
  const keyData = str2ab(key)
  const cryptokey = window.crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'AES-CBC', length: 128 },
    true,
    ['decrypt', 'encrypt']
  )
  return cryptokey
}

const decrypt = async (key: string, iv: string, message: string) => {
  const criptoKey = await importKey(key)
  const ivKey = new Uint8Array(new TextEncoder().encode(iv)).buffer
  const cypherText = b642ab(message)

  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: ivKey },
    criptoKey,
    cypherText
  )

  const textDecripted = new TextDecoder().decode(decrypted)
  return textDecripted
}
const getSecrets = async (
  uuid: string,
  setData: Dispatch<SetStateAction<IDeviceSecrets | undefined>>,
  setError: Dispatch<SetStateAction<AxiosError | undefined>>
) => {
  return await Promise.all([
    api.post('/masterpwd/secrettoken'),
    api.post(`device/${uuid}/secrets`)
  ]).then(([tokens, message]) => {
    decrypt(tokens.data.key, tokens.data.iv, message?.data)
      .then(e => {
        setData(JSON.parse(e).secret)
        setError(undefined)
      })
  }).catch(e => {
    setError(error)
  })
}

export const useDeviceSecrets = (uuid: string) => {
  const [hasMaster, setHasMaster] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [data, setData] = useState<IDeviceSecrets | undefined>(undefined)

  const fetchSecrets = async (func: Promise<AxiosResponse<any, any>>) => {
    await func
      .then(() => {
        setHasMaster(true)
        getSecrets(uuid, setData, setError)
      })
      .catch(e => setError(e))
      .finally(() => {
        setLoading(false)
      })
  }

  const checkPassword = (values: any) => {
    setLoading(true)
    fetchSecrets(api.post('masterpwd/masterpwd', values))
  }

  useEffect(() => {
    fetchSecrets(api.get('/masterpwd/check/masterpwd'))
  }, [uuid])


  return { hasMaster, checkPassword, data, loading, error }
}
