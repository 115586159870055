import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FormOnboard from '@components/OnBoard/FormOnboard'
import { api } from '@helpers/api'
import { Navbar, Image, Logo } from './style'
import { useUser } from '@contexts/UserContext'
import { Col } from 'antd'
import FeedbackContext from '@contexts/FeedbackContext'
import MessageProvider from '@contexts/MessageContext'
import Title from '@components/common/Title'
import { ICompany } from '@/types/ICompany'

const useOnboardData = () => {
  const [company, setCompany] = useState<ICompany>()
  const [showForm, setShowForm] = useState(false)
  const [hasCompany, setHasCompany] = useState(false)
  const [onboardStatus, setOnboardStatus] = useState('')
  const { setIsThemeDark, user } = useUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (user.level === 1) {
      api.get('/onboard/customer').then(response => {
        if (response.data.length) {
          const companyData = response.data[0]
          setCompany(companyData)
          setOnboardStatus(companyData.onboard_status)
          setShowForm(true)
          setHasCompany(true)
          setIsThemeDark(false)

          navigate(`/onboard/customer/${companyData.uuid}`, {
            state: response.data
          })
        } else {
          setHasCompany(false)
        }
      })
    }
  }, [user.level, navigate, setIsThemeDark])

  return { company, showForm, hasCompany, onboardStatus }
}

const FormNavbar = () => {
  return (
    <Navbar>
      <Logo
        src="/assets/images/logo-opendatacenter-white.png"
        alt="Logo OPEN"
        data-cy="onboard-logo"
      />
    </Navbar>
  )
}

const FormImage = () => {
  return (
    <Image
      src="/assets/images/Email-CS_Fluxograma-4.png"
      alt="Imagem 4 da jornada"
      style={{ paddingBottom: '2em', paddingTop: '2em' }}
      data-cy="journey-image"
    />
  )
}

const Onboard = () => {
  const { company, showForm, onboardStatus } = useOnboardData()
  const { user } = useUser()

  return (
    <FeedbackContext>
      {user.level === 1 ? (
        <MessageProvider>
          <FormNavbar />
          {onboardStatus === 'WAITING_FIRST_DEVICE_ACCESS_MAIL' && showForm && (
            <Col display="flex" flexDirection="column" alignItems="center">
              <FormImage />
            </Col>
          )}
          {company?.name && (
            <Title
              name={company.name}
              style={{
                marginBottom: '1%',
                marginTop: '1%',
                display: 'flex',
                justifyContent: 'center'
              }}
            />
          )}
          {showForm && company ? (
            <FormOnboard companyUuid={company.uuid} company={company}/>
          ) : null}
        </MessageProvider>
      ) : null}
    </FeedbackContext>
  )
}

export default Onboard
