import { useTranslation } from 'react-i18next'
import { Form, Row, Button, Typography } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import useForm from '@hooks/useForm'
import { api } from '@helpers/api'
import { OnboardText } from '@pages/Onboard/style'
import FormField from '@components/common/FormField'
import { useFormFields } from '@hooks/useFormFields'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import type { IOnboard } from '@/types/IOnboard'
import { useUser } from '@contexts/UserContext'
import { ICompany } from '@/types/ICompany'

const { Title } = Typography;

const formatTime = (time: dayjs.Dayjs | null) =>
	time ? time.format('HH:mm:ss') : null

const handleFormSubmit = async (values: IOnboard, onFinish: Function) => {
	const formattedValues = {
		...values,
		birthday: values.birthday
			? dayjs(values.birthday).format('YYYY-MM-DD')
			: null,
		cs_working_time_start_at: formatTime(values.cs_working_time_start_at),
		cs_working_time_end_at: formatTime(values.cs_working_time_end_at),
		cs_backup_time_start_at: formatTime(values.cs_backup_time_start_at),
		cs_backup_time_end_at: formatTime(values.cs_backup_time_end_at),
		cs_maintenance_time_start_at: formatTime(
			values.cs_maintenance_time_start_at
		),
		cs_maintenance_time_end_at: formatTime(values.cs_maintenance_time_end_at),
    phones:[values.phones]
	}

	onFinish(formattedValues)
}

const FormOnboardLayout = ({
	formFields,
	isPostponed,
	handlePostponed,
	handleSubmitButton,
	disabled
}: any) => (
	<>
		<Row
			gutter={[16, 20]}
			style={{
				width: '100%',
				padding: '3%',
				marginLeft: '2.5%',
				justifyContent: 'end'
			}}
		>
			{formFields.map((field: any) => (
				<FormField
					key={field.name}
					name={field.name}
					label={field.label}
					rules={field.rules}
					xs={24}
					md={8}
				>
					{field.component}
				</FormField>
			))}
		</Row>
		<Row gutter={[16, 20]} style={{ width: '100%' }} justify="end">
			<Form.Item>
				{!isPostponed && (
					<Button
						data-cy="postpone-button"
						type="primary"
						onClick={handlePostponed}
					>
						Postpone
					</Button>
				)}
				<Button
					type="primary"
					onClick={handleSubmitButton}
					disabled={disabled}
					style={{ marginLeft: '10px' }}
				>
					Submit
				</Button>
			</Form.Item>
		</Row>
	</>
)

const FormOnboard = ({ companyUuid, company }: { companyUuid: string, company: ICompany}) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const { onFinish, disabled, contextHolder, responseData } = useForm(
		'create',
		() => setTimeout(() => navigate('/'), 2000),
		`/onboard/customer/${companyUuid}`
	)
	const messageApi = useGenericContext(MessageContext)
	const { user, setUser } = useUser()
	const navigate = useNavigate()
	const formFields = useFormFields(company)


	const handlePostponed = () => {
		api.put(`/session/user/postponed`, { postponed: true }).then(response => {
			localStorage.setItem('POSTPONED', 'true')
			setUser({ ...user, postponed: response.data.postponed })
			navigate('/')
		})
	}

	useEffect(() => {
		if (responseData) {
			messageApi.success(t('FORMONBOARD.SUCCESS_MESSAGE'), 7)
		}
	}, [responseData])

	return (
		<>
			<OnboardText>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<p style={{ textAlign: 'center' }}>{t('FORMONBOARD.JOURNEY')}</p>
					<p style={{ textAlign: 'center' }}>
						{t('FORMONBOARD.QUESTIONNAIRE')}
					</p>
				</div>
			</OnboardText>
			<Title
				level={2}
				style={{
					marginBottom: '1%',
					marginTop: '1%',
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				{responseData?.companyName || ''}
			</Title>
			<Form
				form={form}
				layout="vertical"
				onFinish={values => handleFormSubmit(values, onFinish)}
				style={{ width: '95vw' }}
				data-cy="form-onboard"
			>
				{contextHolder}
				<FormOnboardLayout
					formFields={formFields}
					isPostponed={user?.postponed ?? false}
					handlePostponed={handlePostponed}
					handleSubmitButton={() => form.submit()}
					disabled={disabled}
				/>
			</Form>
		</>
	)
}

export default FormOnboard
